import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import logo from '../../../img/logo256wthoutBg.png';

export default function RegRoutes({ onRouteChange }) {
  return (
    <Router>
      <div className='menu-logo-container'>
        <nav>
          <ul>
            <div className='menu-logo-sub-container'>
                <img style={{maxWidth:'45px', marginRight:'5px'}} src={logo}></img>
                <h2 style={{color:'#b6b6b6'}}>Quark</h2>
            </div>
            <div>
                <li>
                <Link to='/' onClick={() => onRouteChange('/')}>
                    Главная
                </Link>
                </li>
                <li>
                <Link to='/repository' onClick={() => onRouteChange('/repository')}>
                    Репозитории
                </Link>
                </li>
                <li>
                <Link to='/login' onClick={() => onRouteChange('/login')}>
                    login
                </Link>
                </li>
            </div>
            <div style={{maxWidth:'137.64px', minWidth:'137.64px'}}></div>
          </ul>
        </nav>

        <Routes>
          <Route path='/repository' />
          <Route path='/login' />
        </Routes>
      </div>
    </Router>
  );
}