import React, { useState, useEffect, useRef } from 'react';

const CustomSelect = () => {
  const [selectedOption, setSelectedOption] = useState('Test');
  const [showOptions, setShowOptions] = useState(false);
  const selectRef = useRef(null);

  const options = ['Test', 'Test1', 'Test2', 'Test3'];

  const handleSelect = (option) => {
    setSelectedOption(option);
    setShowOptions(false);
  };

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  useEffect(() => {
    const closeOptions = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener('click', closeOptions);

    return () => {
      document.removeEventListener('click', closeOptions);
    };
  }, []);

  return (
    <div className="custom-select" style={{ width: '200px' }} ref={selectRef}>
      <div className="select-selected" onClick={toggleOptions}>
        {selectedOption}
      </div>
      {showOptions && (
        <div className="select-items">
          {options.map((option, index) => (
            <div
              key={index}
              className={`select-item ${selectedOption === option ? 'same-as-selected' : ''}`}
              onClick={() => handleSelect(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
