import React from 'react';
import ReactDOMClient from 'react-dom/client';
import App from './App';

document.addEventListener("DOMContentLoaded", function () {
    const content = document.getElementById("root");
  
    // Обработчик события прокрутки для позиционирования кастомного скроллбара
    content.addEventListener("scroll", function () {
      const scrollBar = document.querySelector(".custom-scrollbar");
      scrollBar.style.top = content.scrollTop + "px";
    });
  });





  
const root = ReactDOMClient.createRoot(document.getElementById('root'));
root.render(
    <div className='container'>
      <App />
    </div>
)

