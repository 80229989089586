import React, { useState } from 'react';
import RegRoutes from './components/auth/routes/RegRoute';
import HomePage from './components/HomePage';
import Repository from './components/auth/register/Repository';
import Login from './components/auth/login/Login';

export default function App() {
  const [currentRoute, setCurrentRoute] = useState(() => window.location.pathname);

  const handleRouteChange = (route) => {
    setCurrentRoute(route);
  };

  return (
    <div className='sub-container'>
      <RegRoutes onRouteChange={handleRouteChange} />
      {currentRoute === '/' && <HomePage />}
      {currentRoute === '/repository' && <Repository />}
      {currentRoute === '/login' && <Login />}
    </div>
  );
}