import React from 'react';
import logo  from '../img/logo512.png';
import CustomSelect from './CustomSelect';
export default function HomePage() {
  return (
    <div className='home-page-container'>
      <div className='logo-container'>
        <img src={logo} alt="logo512"/>
      </div>
      <div className='header-text'>Размещайте на платформе <span style={{color: "#53e567"}}>Quark!</span></div>
      <h3 className='header-sub-text'>Открывайте, создавайте и делитесь контентом на <br></br>открытой платформе <span style={{color: "#53e567"}}>Quark</span> для сообщества.</h3>
      <div className='button-container'>
        <button className='start-button'>Начать</button>
      </div>
      
      <div className='down-hp-container'>
        <div className='dhp-container-text'>
          <h2 className='dhp-container-sub-title'>Найдите то, что <br></br> вам нужно!</h2>
          <h4 className='dhp-container-sub-text'>Ищите, взаимодействуйте и делитесь необходимым контентом с другими пользователями <span style={{color: "#53e567"}}>Quark!</span></h4>
        </div>
        <div>
          <div className='con'>
            <div className='search'>
              <input className='search-input' id='search' type="text" placeholder='Поиск...' autoComplete='off'/>
              <div className='display-sortby' style={{padding:'10px', minWidth:'60px'}}>Sort by</div>
              <CustomSelect />
            </div>
          </div>
        </div>
        <div>
        </div>
      </div>
    </div>
  )
}
